import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="p-5 md:p-10 text-sm bg-zinc-100">
        <div className="mb-5 inline-block">Welcome to <a href="https://www.bradient.com/" title="The Web Design Los Angeles" target="_blank">The Web Design Los Angeles</a>, your premier resource for modern web design and ecommerce insights. Our expert team curates the most informative and inspiring content to keep you at the forefront of the industry. Dive into a wealth of articles covering the latest trends, practical tips, and innovative techniques in web design and ecommerce. Tailored for both experienced designers and beginners, our resources equip you with essential knowledge and tools for success. From creative website design to effective ecommerce strategies, we ensure you're well-versed in every aspect. Explore, learn, and excel with us in the dynamic world of web design.</div>
        <div className="mt-5 p-5 text-sm overflow-hidden bg-zinc-50">
          <h2 className="font-bold text-xl mb-5 border-b pb-4 border-gray-600">LATEST ARTICLES</h2>
          <ul className="divide-y divide-gray-200">
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <li key={post.fields.slug} className="py-4">
                  <Link to={post.fields.slug} itemProp="url" className="post-link">
                    <h2 className="font-semibold text-md">
                        <span itemProp="headline">{title}</span>
                    </h2>
                      <p
                        className="text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                  </Link>
                </li>
              )
            })}
          </ul>
          <Link to="/blog"><button className="w-full mt-3 p-3 text-md uppercase border border-zinc-400 font-semibold text-black shadow-sm hover:bg-zinc-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Show All Post</button></Link>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Connecting clients with Agencies" />

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } } 
      sort: { fields: frontmatter___date, order: DESC }
      limit: 20
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

